.private-settings {

}

.private-settings .server_manager {

}

.private-settings .server_manager .server_manager_applications .server_manager_application {
    padding-bottom: 30px;
}

.private-settings .server_manager .server_manager_applications .server_manager_title {
    color: rgba(0,0,0,.45);
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    padding-left: 20px;
}

.private-settings .server_manager .server_manager_applications .server_manager_application .server_manager_label {
    display: inline-block;
    width: 200px;
    font-size: 20px;
    margin-left: 20px;
}

.private-settings .server_manager .server_manager_applications .server_manager_application .server_manager_action {
    display: inline-block;
}

.private-settings .server_manager .server_manager_applications .server_manager_application .server_manager_param {
    margin-left: 30px;
}

.private-settings .server_manager .server_manager_applications .server_manager_application .server_manager_param .server_manager_param_label {
    display: inline-block;
    width: 100px;
    line-height: 32px;
}

.private-settings .server_manager .server_manager_applications .server_manager_application .server_manager_param .server_manager_param_value {
    display: inline-block;
}

.private-settings .server_manager .server_manager_applications .server_manager_application .server_manager_description {
    display: block;
    margin-left: 20px;
}

.private-settings .engine_parameters_manager .engine_parameters_choices {
    list-style: square;
}

.private-settings .engine_parameters_manager .engine_parameters_choices .engine_parameters_choice .engine_parameters_choice_label {
    display: inline-block;
    width: 170px;
}

.private-settings .engine_parameters_manager .engine_parameters_preview {
    display: block;
    white-space: pre;
}

